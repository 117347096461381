@import '_variables';

@font-face {
  font-family: "garanteasy";
  src: url('https://static.garanteasy.com/font/garanteasy.eot'); /* IE9*/
  src: url('https://static.garanteasy.com/font/garanteasy.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("https://static.garanteasy.com/font/garanteasy.woff2") format("woff2"),
  url("https://static.garanteasy.com/font/garanteasy.woff") format("woff"),
  url('https://static.garanteasy.com/font/garanteasy.ttf') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('https://static.garanteasy.com/font/garanteasy.svg#garanteasy') format('svg'); /* iOS 4.1- */
}

[class^="garanteasy-"], [class*=" garanteasy-"] {
  font-family: 'garanteasy' !important;
  font-size:1em;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $garanteasy-green;
}

.garanteasy-ICONA-SCONTRINO-SENZA-SFONDO:before { content: "\ea01"; }
.garanteasy-acquistibucata:before { content: "\ea02"; }
.garanteasy-assistenzabucata:before { content: "\ea03"; }
.garanteasy-assistenzabucato:before { content: "\ea04"; }
.garanteasy-bilanciabucata:before { content: "\ea05"; }
.garanteasy-cadutaaccidentalebucata:before { content: "\ea06"; }
.garanteasy-callcenterbucata:before { content: "\ea07"; }
.garanteasy-cartadicreditobucata:before { content: "\ea08"; }
.garanteasy-codiceabarrebucato:before { content: "\ea09"; }
.garanteasy-dannobucata:before { content: "\ea0a"; }
.garanteasy-difettobucata:before { content: "\ea0b"; }
.garanteasy-ecomnegoziobucato:before { content: "\ea0c"; }
.garanteasy-edificiobucato:before { content: "\ea0d"; }
.garanteasy-eventoatmosferico:before { content: "\ea0e"; }
.garanteasy-fabbricabucato:before { content: "\ea0f"; }
.garanteasy-fattura:before { content: "\ea10"; }
.garanteasy-negoziobucato:before { content: "\ea11"; }
.garanteasy-ombrellobucato:before { content: "\ea12"; }
.garanteasy-puntodidomandabucato:before { content: "\ea13"; }
.garanteasy-regalobucato:before { content: "\ea14"; }
.garanteasy-riciclobucata:before { content: "\ea15"; }
.garanteasy-scontrinobucato:before { content: "\ea16"; }
.garanteasy-scontrinomanobucato:before { content: "\ea17"; }
.garanteasy-trasportobucato:before { content: "\ea18"; }


